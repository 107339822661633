<template>
  <div class="h-full flex justify-content-start">
    <div class="cards card-tile flex flex-column w-full" style="gap: 2rem;">
      <div class="flex flex-row justify-content-center w-12">
        <div class="flex flex-column">
          <div class="text-center">
            <p class="text-2xl">{{ step.label }}</p>
          </div>
          <div class="flex flex-column cards card-tile" style="gap: 2rem;">
            <Card>
              <template #content>
                <independent-documents
                  :documents-types="documentTypes"
                  :independent-id="this.localIndependent.id"
                  @allDocumentTypeValidated="allDocumentTypeValidated"
                />
                <Divider/>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import {getDocumentTypes} from "@/utils/IndependentUtil";
import IndependentDocuments from "@/components/RH/IndependentDocuments.vue";

export default {
  name: "Documents",
  components: {IndependentDocuments},
  emits: ['canValidated'],
  props: ['independent', 'step'],
  computed: {
    ...mapState({
      settings: state => state.misc.settings,
      constants: state => state.constants
    })
  },
  data () {
    return {
      localIndependent: this.independent,
      documentTypes: [],
    }
  },
  created () {
    this.initDocumentTypes()
  },
  methods: {
    initDocumentTypes () {
      this.documentTypes = getDocumentTypes(
          { independent: this.localIndependent, constants: this.constants, settings: this.settings })
    },
    allDocumentTypeValidated (value) {
      this.$emit('canValidated', value)
    }
  }
}
</script>

<style scoped>
@import '../../../../assets/css/stepsComponents.css';
.k-upload .p-fileupload .p-button {
  height: 2.35rem !important;
}
</style>
