<template>
  <div class="px-3 py-4 md:px-6 lg:px-8">
    <div class="flex align-items-center">
      <div class="flex align-items-center justify-content-evenly w-12rem">
      <!--  comment those due to the arrow disabled  -->
<!--        <Button v-if="getPreviousStep()" class="p-button-outlined p-button-plain p-button-rounded" icon="pi pi-arrow-left" @click="previousStep"></Button>-->
<!--        <span v-if="getPreviousStep()" class="font-medium text-blue-500 text-xl mx-3">{{ getPreviousStep()?.label || '...' }}</span>-->
      </div>
      <div class="flex-grow-1 text-center">
        <ProgressBar :value="config.value" :showValue="true">
          <span class="text-white">{{ config.value }}%</span>
        </ProgressBar>
      </div>
      <div class="flex align-items-center justify-content-evenly w-12rem">
<!--        <span v-if="getNextStep()" class="font-medium text-600 text-xl mx-3">{{ getNextStep()?.label || '...' }}</span>-->
<!--        <Button class="p-button-outlined p-button-plain p-button-rounded" :icon="getNextStep() !== null ? 'pi pi-arrow-right' : 'pi pi-check'" @click="nextStep"></Button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBarHeader",
  emits: ["validate"],
  props: ['items', 'config'],
  data () {
    return {
      progressConfig: this.config
    }
  },
  created () {
  },
  methods: {
    // nextStep () {
    //   if ((this.progressConfig.currentStep + 1) <= this.items.length - 1) {
    //     this.progressConfig.currentStep++
    //     this.updateProgressValue()
    //   } else {
    //     this.$emit('validate')
    //   }
    // },
    // previousStep () {
    //   if ((this.progressConfig.currentStep - 1) >= 0) {
    //     this.progressConfig.currentStep--
    //     this.updateProgressValue()
    //   }
    // },
    // getPreviousStep () {
    //   const previousStep = this.progressConfig.currentStep - 1
    //   if (previousStep >= 0) {
    //     return this.items[previousStep]
    //   } else {
    //     return null
    //   }
    // },
    // getNextStep () {
    //   const nextStep = this.progressConfig.currentStep + 1
    //   if (nextStep <= this.items.length - 1) {
    //     return this.items[nextStep]
    //   } else {
    //     return null
    //   }
    // },
    updateProgressValue () {
      this.progressConfig.value = this.items.reduce(((previousValue, currentValue, currentIndex) => {
        if (currentIndex < this.progressConfig.currentStep) {
          return previousValue + currentValue.value
        } else {
          return previousValue
        }
      }), 0)
    }
  }
}
</script>

<style scoped>

</style>
