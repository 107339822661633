<template>
  <div v-if="!loading" class="flex flex-column h-full overflow-hidden">
    <div class="flex pl-5 pt-3 pb-3 align-items-center w-full bg-white" style="border-top-left-radius: 30px;">
      <div class="flex flex-row align-items-center w-full" style="gap: 1.5rem;">
        <div>
          <NameHeader :independent="independent" :backward-button="false" :show-money-account="false"/>
        </div>
        <div class="flex flex-column w-10">
          <progress-bar-header
              class="flex-grow-1"
              :items="steps"
              :config="progressConfig"
              @canValidated="enableValidation"
              @validate="save"
              @updateProgressValue="updateProgressValue" />
        </div>
      </div>
    </div>

    <div class="step-component flex p-3 overflow-auto w-full lg:justify-content-center">
      <Button
          :label="progressConfig.currentStep === steps.length - 1 ? 'Terminer' : ('Sauvegarder ' + steps[progressConfig.currentStep].label)"
          :icon="progressConfig.currentStep === steps.length - 1 ? 'pi pi-check' : 'pi pi-save'"
          class="p-button-rounded z-5 p-button-md border-transparent absolute transition-colors transition-duration-300 bg-blue-500 hover:bg-blue-600 bottom-right-lg"
          :disabled="disableValidation"
          @click="onSubmit"
      />
      <!--   // todo: should we decrease the progressConfig.value on each previous click ? Also see the initialization ?   -->
      <Button
          v-if="progressConfig.currentStep > 0"
          label="Précédent"
          icon="pi pi-chevron-left"
          class="z-5 p-button-rounded p-button-md border-transparent absolute transition-colors transition-duration-300 bg-blue-500 hover:bg-blue-600 bottom-left-lg"
          @click="progressConfig.currentStep -= 1"
      />
      <div class="flex flex-column justify-content-center w-full h-full">
          <component
              :is="steps[progressConfig.currentStep].component"
              :independent="independent"
              :step="steps[progressConfig.currentStep]"
              :ref="steps[progressConfig.currentStep].ref"
              @canValidated="enableValidation"
          />
      </div>
    </div>
  </div>
  <div v-else class="overflow-hidden flex">
    <ProgressSpinner></ProgressSpinner>
  </div>
</template>

<script>
import NameHeader from "@/components/NameHeader";
import ProgressBarHeader from "@/components/Profil/ProfilValidation/Header/ProgressBarHeader";
import Profil from "@/components/Profil/ProfilValidation/Steps/Profil";
import Documents from "@/components/Profil/ProfilValidation/Steps/Documents";
import Mission from "@/components/Profil/ProfilValidation/Steps/Mission";
import { markRaw } from "vue";
import {mapState} from "vuex";

export default {
  name: 'Profil',
  // eslint-disable-next-line vue/no-unused-components
  components: { NameHeader, ProgressBarHeader, Profil, Mission, Documents },
  data () {
    return {
      loading: true,
      disableValidation: false,
      formData: null,
      progressConfig: {
        value: 0,
        currentStep: 0
      },
      steps: [
        {
          id: 1,
          label: this.$t('profil'),
          value: 25,
          component: markRaw(Profil),
          ref: 'profilIndeForm'
        },
        {
          id: 2,
          label: this.$t('mission'),
          value: 50,
          component: markRaw(Mission),
          ref: 'missionIndeForm'
        },
        {
          id: 4,
          label: this.$t('documents'),
          value: 25,
          component: markRaw(Documents),
          ref: 'docIndeForm'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      independent: state => state.independent.independent,
      missions: state => state.mission.missions,
      simulation: state => state.simulation,
      constants: state => state.constants
    })
  },
  async created () {
    await this.$store.dispatch('independent/getOneIndependentByUser', this.me.id).then(async () => {
      this.loading = false
    })
  },
  methods: {
    async onSubmit () {
      if (this.progressConfig.currentStep === this.steps.length - 1) {
        await this.save()
      } else {
        const isSubmitValid = await this.$refs[this.steps[this.progressConfig.currentStep].ref].onSubmit()
        if (isSubmitValid) {
          this.progressConfig.currentStep += 1
          this.updateProgressValue()
        }
      }
    },
    enableValidation (value) {
      this.disableValidation = !value
    },
    async save () {
      this.$store.state.misc.loading = true

      // todo: must be in the back as a logic
      for (const mission of this.missions) {
        const dataMission = { status: this.constants.MISSION_STATUS.PENDING_CONTRACT }
        const missionBd = await this.$store.dispatch('mission/saveMission', mission);
        await this.$store.dispatch('mission/validateMission', { id: missionBd.id, payload: dataMission })
      }

      this.$store.state.misc.loading = false
      const data = Object.assign({}, this.independent)
      delete data.id

      await this.$store.dispatch('independent/validateIndependent', { id: this.independent.id, payload: data })
      await this.$router.push({name: 'Home'})
    },
    updateProgressValue () {
      this.progressConfig.value = this.steps.reduce(((previousValue, currentValue, currentIndex) => {
        if (currentIndex < this.progressConfig.currentStep) {
          return previousValue + currentValue.value
        } else {
          return previousValue
        }
      }), 0)
    }
  }
}
</script>

<style scoped>
.step-component {
  height: calc(75vh);
  background-color: #F2F4F6;
}
</style>
