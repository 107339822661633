<template>
  <div class="flex flex-row justify-content-center h-full w-full">
    <div v-if="missions.length > 1" class="w-3">
      <div class="px-3 py-4 md:px-6 lg:px-4 flex align-items-center justify-content-center"
           style="position: fixed; left: 20%">
        <ul class="list-none p-0 m-0">
          <li v-for="(mission, index) in missions" :key="index" class="flex cursor-pointer"
              @click="currentMission = index">
            <div class="flex flex-column align-items-center step-mission-item" style="width:2rem">
              <span v-if="mission.status === constants.MISSION_STATUS.COMPLETE"
                    class="bg-green-500 text-0 flex align-items-center justify-content-center border-circle"
                    style="min-width:2rem; min-height: 2rem">
                <i class="pi pi-check"></i>
              </span>
              <span v-else-if="currentMission === index"
                    class="surface-0 text-blue-500 border-blue-500 border-2 border-circle flex align-items-center justify-content-center border-circle"
                    style="min-width:2rem; min-height: 2rem">
                {{ index + 1 }}
              </span>
              <span v-else-if="mission.status === 'running'"
                    class="surface-0 text-300 border-2 border-circle flex align-items-center justify-content-center border-circle"
                    style="min-width:2rem; min-height: 2rem">
                {{ index + 1 }}
              </span>
              <span v-else
                    class="surface-0 text-blue-500 border-blue-500 border-2 border-circle flex align-items-center justify-content-center border-circle"
                    style="min-width:2rem; min-height: 2rem">
                {{ index + 1 }}
              </span>
              <div
                  v-if="index < missions.length - 1"
                  class="h-full"
                  :class="{'bg-blue-500': currentMission === index, 'surface-300': currentMission !== index }"
                  style="width: 2px; min-height: 4rem"></div>
            </div>
            <div class="ml-3">
              <div class="font-medium mb-2"
                   :class="{'text-blue-500': currentMission === index, 'text-900': currentMission !== index }">
                {{ 'Mission ' + (index + 1) }}
              </div>
              <span class="line-height-3"
                    :class="{'text-blue-500': currentMission === index, 'text-700': currentMission !== index }">{{
                  mission.metadata.clientContract.name
                }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="missions && missions.length > 0 && missions[currentMission]">
      <mission-form
          ref="currentMissionForm"
          v-for="(mission, index) in missions" :key="index"
          :mission="mission"
          :step="step"
          :style="currentMission === index ? '' : 'display: none !important'"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Alert from "@/utils/Alert";
import MissionForm from "@/components/Mission/Prospect/MissionForm.vue";

export const defaultMission = {
  status: 'incomplete',
  jsonMission: {
    jobTitle: null,
    jobBasicDescription: null,
    jobDescription: null,
    startingDate: null,
    initialDuration: null,
    initialEndingDate: null,
    renewal: null,
    trialPeriod: null,
    tjm: null,
    noticePeriod: {
      trial: {
        value: 0,
        unit: null
      },
      global: {
        value: 0,
        unit: null
      }
    }
  },
  jsonClientFinal: {
    fullName: null,
    address: null,
  },
  jsonProvider: {
    name: '',
    payment: {
      value: 0,
      unit: '€'
    }
  },
  jsonCost: {
    managementFee: 0,
    cap: 0,
    negotiatedRate: {
      value: 0,
      unit: ''
    },
    forfaitFacturationMode: null,
    teleworking: null,
    teleworkingRate: null,
    settlementPeriod: {
      value: '',
      unit: ''
    },
    solvency: {
      value: '',
      cap: ''
    },
    onCall: '',
    travelFee: ''
  },
  clientContract: null,
  contact: null,
  signatory: null,
  commercial: null,
  independent: {
    id: null,
    status: null,
    first_name: null,
    last_name: null,
    email: null,
    phone: null
  },
  notes: ''
};

export default {
  name: "Mission",
  components: {MissionForm},
  emits: ['canValidated'],
  props: ['step'],
  computed: {
    ...mapState({
      independent: state => state.independent.independent,
      simulation: state => state.simulation.simulation,
      missions: state => state.mission.missions,
      constants: state => state.constants
    })
  },
  data() {
    return {
      isMissionFinalClientDifferent: false,
      currentMission: 0
    }
  },
  async created() {
    await this.$store.dispatch('simulation/lastSharedSimulationByIndependent', {independentId: this.independent.id})
    await this.prepareMissions(this.simulation.jsonMissions.missions)
    // todo: should define some validations
    this.$emit('canValidated', true)
  },
  methods: {
    async prepareMissions(simuMissions) {
      let missions = []

      // original mission
      for (const mission of simuMissions) {
        let newMission = null
        if (mission.link) {
          newMission = await this.$store.dispatch('mission/getOneMission', mission.link)
          if (newMission.jsonMission.startingDate) {
            newMission.jsonMission.startingDate = new Date(newMission.jsonMission.startingDate)
          }
          // if (newMission.jsonClientFinal.fullName) {
          //   this.isMissionFinalClientDifferent = true
          // }
        } else {
          newMission = JSON.parse(JSON.stringify(defaultMission))
          // todo: simulation negotiated rate unit must be the same as the mission negotiated rate unit one (need little refactor)
          switch (mission.type) {
            case this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE:
              newMission.jsonCost.negotiatedRateUnit = this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE;
              newMission.jsonCost.negotiatedRate.value = mission.base;
              break;
            case this.constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE:
              newMission.jsonCost.negotiatedRateUnit = this.constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE;
              newMission.jsonCost.negotiatedRate.value = mission.base;
              break;
            case this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE:
              newMission.jsonCost.negotiatedRateUnit = this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE;
              newMission.jsonCost.negotiatedRate.value = mission.base;
              break;
          }
          newMission.jsonCost.managementFee = this.simulation.jsonMissions.managementFee.value
          newMission.jsonCost.cap = this.simulation.jsonMissions.managementFee.cap
          // newMission.jsonMission.tjm = (mission.type === 'TJM') ? mission.base : ''
          newMission.commercial = this.simulation.commercial
          newMission.independent = this.independent
          newMission.metadata = {
            clientContract: {
              name: null
            },
            clientContact: {}
          }
        }
        missions.push(newMission)
      }
      await this.$store.dispatch('mission/setProfilMission', missions)
    },
    async onSubmit() {
      const simulationCopy = Object.assign({}, this.simulation)
      // const submitMissions = []
      let index = 0
      let leastOneMissionSaved = false
      let mostEarlyMissionStartingDate
      // prevent the foreground black flash on validation error
      this.$store.state.misc.loading = true

      // eslint-disable-next-line no-unused-vars
      for (const mission in this.missions) {
        const submittedForm = await this.$refs.currentMissionForm[index].onSubmit()

        if (!submittedForm.validate) {
          if (leastOneMissionSaved) await this.refresh(simulationCopy)
          this.$store.state.misc.loading = false
          this.currentMission = index
          return false
        }

        // init after getting the first mission payload
        if (index === 0) {
          mostEarlyMissionStartingDate = new Date(submittedForm.mission.jsonMission.startingDate).toISOString()
        }

        submittedForm.mission.jsonCost.managementFee = this.simulation.jsonMissions.managementFee.value
        submittedForm.mission.jsonCost.cap = this.simulation.jsonMissions.managementFee.cap

        if (!this.isMissionFinalClientDifferent) {
          submittedForm.mission.jsonClientFinal.fullName = submittedForm.mission.metadata.clientContract.name ?? null
          submittedForm.mission.jsonClientFinal.address = submittedForm.mission.metadata.clientContract.address ?? null
        }

        const missionStartingDate = new Date(submittedForm.mission.jsonMission.startingDate).toISOString()
        // comparing ISO string date
        if (mostEarlyMissionStartingDate > missionStartingDate) {
          mostEarlyMissionStartingDate = missionStartingDate
        }
        if (index === this.missions?.length - 1) {
          submittedForm.mission.independent.profil = this.independent.profil
          submittedForm.mission.independent.profil.workContract.startingDate = mostEarlyMissionStartingDate
        }

        const res = await this.$store.dispatch('mission/saveMission', submittedForm.mission)
        leastOneMissionSaved = true
        // todo: Recheck needed, consider that there's no need to check with indexOf (see the comment below the loop) with the mission object as param due to no particular difference, also the missions cannot be reorder by the admin ?

        // On crée un lien de la nouvelle mission dans le json missions de la simulation
        if (!submittedForm.mission.id) {
          simulationCopy.jsonMissions.missions[index].link = res.id
        }
        Alert.successMessage(this, 'saveSpecificMission', {target: index})
        ++index
      }

      // for test siret: 36252187900035
      // Creation/Update des missions

      // for (const m of submitMissions) {
      //   m.independent = m.independent.id
      //
      //   // m.jsonCost.negotiatedRate.value = this.simulation.jsonMissions.missions[this.missions.indexOf(m)].base
      //   m.jsonCost.managementFee = this.simulation.jsonMissions.managementFee.value
      //   m.jsonCost.cap = this.simulation.jsonMissions.managementFee.cap
      //
      //   if (!this.isMissionFinalClientDifferent) {
      //     m.jsonClientFinal.fullName = m.metadata.clientContract.name ?? null
      //     m.jsonClientFinal.address = m.metadata.clientContract.address ?? null
      //   }
      //
      //   const res = await this.$store.dispatch('mission/saveMission', m)
      //   // On crée un lien de la nouvelle mission dans le json missions de la simulation
      //   if (!m.id) {
      //     simulationCopy.jsonMissions.missions[this.missions.indexOf(m)].link = res.id
      //     switch (this.simulation.jsonMissions.missions[this.missions.indexOf(m)].type) {
      //       case 'TJM':
      //         // m.jsonCost.negotiatedRateUnit = 'TJM';
      //         simulationCopy.jsonMissions.missions[this.missions.indexOf(m)].base = m.jsonCost.negotiatedRate.value ? m.jsonCost.negotiatedRate.value * 100 : null
      //         break;
      //       case 'TH':
      //         // m.jsonCost.negotiatedRateUnit = 'horaire';
      //         simulationCopy.jsonMissions.missions[this.missions.indexOf(m)].base = m.jsonCost.negotiatedRate.value ? m.jsonCost.negotiatedRate.value * 100 : null
      //         break;
      //       case 'PACKAGE':
      //         // m.jsonCost.negotiatedRateUnit = 'forfait';
      //         simulationCopy.jsonMissions.missions[this.missions.indexOf(m)].base = m.jsonCost.dueDates[0].price ? m.jsonCost.dueDates[0].price * 100 : null
      //         break;
      //     }
      //   }
      //   Alert.successMessage(this, 'saveSpecificMission', { target: index })
      // }

      //  Force Refresh
      await this.refresh(simulationCopy)
      this.$store.state.misc.loading = false
      return true
    },
    async refresh(simulationCopy) {
      this.missions = []
      await this.$store.dispatch('simulation/updateMissionsSimulation', simulationCopy)
      await this.$store.dispatch('simulation/lastSharedSimulationByIndependent', {independentId: this.independent.id})
      await this.prepareMissions(this.simulation.jsonMissions.missions)
    }
  }
}
</script>

<style scoped>
@import '../../../../assets/css/stepsComponents.css';
</style>
<style>
.disabled-radio-absolute .p-radiobutton .p-hidden-accessible {
  position: relative !important;
}
</style>
